import React from "react"
import PropTypes from "prop-types"

import styles from "./Header.module.less"

const Header = ({ title }) => (
  <>
    {title && <h2 className={styles.header}>{title}</h2>}
    <p className={styles.text}>
      Calculate estimated payments, then see if you qualify for a business loan
    </p>
  </>
)

Header.propTypes = {
  title: PropTypes.string,
}

Header.defaultProps = {
  title: null,
}

export default Header
