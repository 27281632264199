import React from "react"

import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"

import BusinessLoanCalculator from "@src/components/BusinessLoanCalculator"

import DefaultSeparator from "./_component_default_separator"

const CalculatorPage = () => {
  const title = "Business Loan Calculator"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <Page
      title={title}
      header={<Header />}
      headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
      footer={<Footer />}
    >
      <Columns>
        <Column width={{ desktop: 8, mobile: 12 }}>
          <DefaultSeparator />
          <BusinessLoanCalculator />
          <DefaultSeparator />
        </Column>
        <Column width={{ desktop: 4, mobile: 12 }}>[right rail]</Column>
      </Columns>
    </Page>
  )
}

export default CalculatorPage
