// ref: https://github.com/fundera/fundera-gatsby/blob/43eba22/packages/business-loan-calculator/src/BusinessLoanCalculator/helpers.js
export function validate(formData) {
  const errors = {}
  const { loanAmount, additionalPayment, interestRate, time, timeOption } =
    formData
  if (loanAmount < 100 || loanAmount > 1000000) {
    errors.loanAmount = "Invalid amount"
  }
  if (!loanAmount) {
    errors.loanAmount = "Please enter a loan amount"
  }
  if (additionalPayment && Number(additionalPayment) >= Number(loanAmount)) {
    errors.additionalPayment = "Must be less than the loan amount"
  }
  if (interestRate < 0 || interestRate > 100) {
    errors.interestRate = "Invalid interest rate"
  }
  if (!interestRate) {
    errors.interestRate = "Please enter an interest rate"
  }
  if (
    time < 0 ||
    (timeOption === "months" && time > 600) ||
    (timeOption === "years" && time > 50)
  ) {
    errors.time = "Invalid loan term"
  }
  if (!time) {
    errors.time = "Please enter a time"
  }
  return errors
}

export function calculate(formData) {
  const { time, timeOption } = formData
  const interestRate = Number(formData.interestRate) / 100
  const loanAmount = Number(formData.loanAmount)
  const additionalPayment = Number(formData.additionalPayment)

  const monthlyInterestRate = interestRate / 12
  const loanDurationInMonths = timeOption === "months" ? time : time * 12

  const totalLoanCost =
    (monthlyInterestRate * loanAmount * loanDurationInMonths) /
    (1 - 1 / (1 + monthlyInterestRate) ** loanDurationInMonths)
  const totalInterestPaid = totalLoanCost - loanAmount
  const monthlyPayment = totalLoanCost / loanDurationInMonths

  if (!additionalPayment) {
    return {
      loanDurationInMonths,
      monthlyPayment,
      totalLoanCost,
      totalInterestPaid,
      interestRate,
      monthlyInterestRate,
    }
  }

  const monthlyPaymentAdditional =
    totalLoanCost / loanDurationInMonths + additionalPayment
  const loanDurationInMonthsAdditional =
    Math.log(
      1 +
        monthlyInterestRate /
          (monthlyPaymentAdditional / loanAmount - monthlyInterestRate)
    ) / Math.log(1 + monthlyInterestRate)
  const totalLoanCostAdditional =
    (monthlyInterestRate * loanAmount * loanDurationInMonthsAdditional) /
    (1 - 1 / (1 + monthlyInterestRate) ** loanDurationInMonthsAdditional)
  const totalInterestPaidAdditional = totalLoanCostAdditional - loanAmount

  return {
    loanDurationInMonths: loanDurationInMonthsAdditional,
    monthlyPayment: monthlyPaymentAdditional,
    totalLoanCost: totalLoanCostAdditional,
    totalInterestPaid: totalInterestPaidAdditional,
  }
}
