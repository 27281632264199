import React, { useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./RangeInput.module.less"

// We need a workaround Chrome because
// webkit does not support "range-progress" selector.
// This is working using CSS variables
const InputRange = ({ className, ...props }) => {
  const comp = useRef(null)
  const pct =
    ((Number(props.value) - Number(props.min)) / Number(props.max)) * 100

  if (comp.current) {
    comp.current.style.setProperty("--pct", `${pct || 0}%`)
  }

  return (
    <input
      className={classNames(className, styles.input)}
      type="range"
      ref={comp}
      {...props}
    />
  )
}

InputRange.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

export default InputRange
