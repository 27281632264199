import { validate, calculate } from "./helpers"

export const useBusinessLoanCalculator = ({
  time,
  timeOption,
  interestRate,
  loanAmount,
  additionalPayment,
}) => {
  const errors = validate({
    time,
    timeOption,
    interestRate,
    loanAmount,
    additionalPayment,
  })

  if (Object.keys(errors).length > 0) {
    return {
      errors,
      result: null,
    }
  }

  return {
    errors: {},
    result: calculate({
      time,
      timeOption,
      interestRate,
      loanAmount,
      additionalPayment,
    }),
  }
}
