/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import { Text } from "@nerdwallet/react-typography"
import TrackedButton from "@src/components/TrackedButton"
import { FUNDERA_BY_NERDWALLET } from "@src/lib/constants"

import { useApplyUrl } from "@src/hooks/useApplyUrl"

import styles from "./Footer.module.less"

const CTA_TYPE = "business-loan-calculator"

const Footer = () => {
  const applyUrl = useApplyUrl({
    cta_type: CTA_TYPE,
  })
  return (
    <section className={styles.section}>
      <h2 className={styles.header}>
        Get personalized small-business loan rates to compare
      </h2>
      <TrackedButton
        tertiaty
        className={styles.button}
        href={`${applyUrl}&blc=yes`}
        target="_blank"
        data-cta-type={CTA_TYPE}
      >
        compare business loans
      </TrackedButton>
      <Text small color="neutral-darker" className={styles.fullWidth}>
        {FUNDERA_BY_NERDWALLET}
      </Text>
    </section>
  )
}

export default Footer
