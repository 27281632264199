import React, { useState, useRef } from "react"
import PropTypes from "prop-types"

import Button from "@src/components/Button"
import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"

import Field from "@src/components/Field"
import Input from "@src/components/Field/Input"
import PrefixInput from "@src/components/Field/PrefixInput"
import RangeInput from "@src/components/Field/RangeInput"
import Header from "./Header"
import Result from "./Result"
import Footer from "./Footer"

import { useBusinessLoanCalculator } from "./hooks"

import styles from "./BusinessLoanCalculator.module.less"

const BusinessLoanCalculator = ({ title }) => {
  const [loanAmount, setLoanAmount] = useState(null)
  const [time, setTime] = useState(null)
  const [interestRate, setInterestRate] = useState(1)

  const [showResult, setShowResult] = useState(false)

  const resultRef = useRef()

  const { result, errors } = useBusinessLoanCalculator({
    loanAmount,
    time,
    interestRate,
    timeOption: "months",
    additionalPayment: 0,
  })

  const calculate = () => {
    if (!result) return
    setShowResult(true)
    resultRef.current.scrollIntoView()
  }

  return (
    <>
      <section>
        <Header title={title} />
        <form className={styles.form}>
          <Columns gap>
            <Column width={{ desktop: 6, mobile: 12 }}>
              <Field label="Loan amount" error={errors.loanAmount}>
                <PrefixInput
                  name="loanAmount"
                  prefix="$"
                  precision="1"
                  step="1"
                  placeholder="0"
                  min="0"
                  type="number"
                  value={loanAmount || " "}
                  onChange={e =>
                    e.target.validity.valid && setLoanAmount(e.target.value)
                  }
                  data-nw-mp
                  data-calculator-input="Loan Amount"
                />
              </Field>
            </Column>
            <Column width={{ desktop: 6, mobile: 12 }}>
              <Field label="Loan term (months)" error={errors.time}>
                <Input
                  type="number"
                  precision="1"
                  step="1"
                  min="0"
                  max={600}
                  placeholder="0 months"
                  value={time || " "}
                  onChange={e =>
                    e.target.validity.valid && setTime(e.target.value)
                  }
                  data-nw-mp
                  data-calculator-input="Loan Term"
                />
              </Field>
            </Column>
          </Columns>

          <Field
            label="Annual percentage rate (APR)"
            error={errors.interestRate}
          >
            <div className={styles.verticalMargin}>
              <RangeInput
                min="1"
                max="100"
                value={interestRate}
                aria-label={`${interestRate}%`}
                onChange={e => setInterestRate(e.target.value)}
                data-nw-mp
                data-calculator-input="APR"
              />
            </div>
            <span aria-hidden="true">{interestRate}%</span>
          </Field>
          <div className={styles.rates}>
            <span>Not sure?</span> See estimate rates on{" "}
            <a href="https://www.nerdwallet.com/best/small-business/online-business-loans">
              online business loans
            </a>{" "}
            and{" "}
            <a href="https://www.nerdwallet.com/article/small-business/sba-loan-rates">
              SBA loans
            </a>
            .
          </div>

          <div className={styles.button}>
            <Button
              className="clickable"
              primary
              onClick={calculate}
              data-nw-mp
              data-calculator-calculate
            >
              Calculate
            </Button>
          </div>
        </form>
        <div
          aria-live="polite"
          ref={resultRef}
          style={{ scrollMarginTop: "50vh" }}
        >
          {showResult && result && (
            <Result loanAmount={loanAmount} result={result} />
          )}
        </div>
      </section>
      <Footer />
    </>
  )
}

BusinessLoanCalculator.propTypes = {
  title: PropTypes.string,
}

BusinessLoanCalculator.defaultProps = {
  title: "Business loan calculator",
}

export default BusinessLoanCalculator
