import React from "react"
import PropTypes from "prop-types"

import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"

import styles from "./Result.module.less"

const formatMoney = value =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)

const ResultItem = ({ title, children }) => (
  <Columns className={styles.item}>
    <Column width={{ desktop: 12, mobile: 6 }} className={styles.itemHeader}>
      <span className={styles.textSmall}>{title}</span>
    </Column>
    <Column width={{ desktop: 12, mobile: 6 }}>
      <div className={styles.itemText}>{children}</div>
    </Column>
  </Columns>
)

ResultItem.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

const Result = ({
  loanAmount,
  result: { monthlyPayment, loanDurationInMonths },
}) => {
  const totalLoanCost =
    (Math.round(monthlyPayment * 100) / 100) * Number(loanDurationInMonths)
  const totalInterestPaid = totalLoanCost - Number(loanAmount)
  return (
    <Columns className={styles.container}>
      <Column width={{ desktop: 4, mobile: 12 }}>
        <ResultItem title="Monthly payment">
          {formatMoney(monthlyPayment)}
        </ResultItem>
      </Column>
      <Column width={{ desktop: 4, mobile: 12 }}>
        <ResultItem title="Total payment">
          {formatMoney(totalLoanCost)}
        </ResultItem>
      </Column>
      <Column width={{ desktop: 4, mobile: 12 }}>
        <ResultItem title="Total interest paid">
          {formatMoney(totalInterestPaid)}
        </ResultItem>
      </Column>
    </Columns>
  )
}

Result.propTypes = {
  loanAmount: PropTypes.number.isRequired,
  result: PropTypes.shape({
    monthlyPayment: PropTypes.number.isRequired,
    loanDurationInMonths: PropTypes.number.isRequired,
  }).isRequired,
}

export default Result
